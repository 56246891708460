<script setup>
    const { $toasts } = useNuxtApp();
    const { t: $t } = useI18n();

    const props = defineProps({
        value: {
            type: String,
            default: '',
        },
    });

    const copy = () => {
        copyToClipboard(props.value);

        $toasts.flash({
            severity: 'success',
            detail: $t('Copied to clipboard'),
            group: 'tr',
            life: 10000
        });
    }
</script>

<template>
    <Icon name="material-symbols:content-copy" class="clipboard__icon" @click="copy" />
</template>
